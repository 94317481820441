<template>
  <div class="goodsSearch">
    <form action="">
      <van-search
        shape="round"
        background="#fff"
        placeholder="商品名称、型号、品牌"
        show-action
        v-model="searchParam.param.search"
        @search="onSearch"
      >
        <template #left>
          <van-icon class="margin-right-12" name="arrow-left" @click="onClickBack" size="0.42667rem" />
        </template>
        <template #action>
          <div @click="onSearch" style="color:#4D6FF0;margin:0px 10px">
            搜索
          </div>
        </template>
      </van-search>
    </form>

    <div class="search-btn-box" style="display:flex">
      <van-button
        style="border:none;color:#696974"
        icon="arrow-down"
        icon-position="right"
        size="small"
        @click="show = true"
        >
        <div class="van-ellipsis btn-text">
          {{ categoryName ? categoryName : "全部分类" }}
        </div>
        </van-button
      >
      <van-button
        style="border:none;color:#696974"
        icon="arrow-down"
        icon-position="right"
        size="small"
        @click="show2 = true"
      >
        <div class="van-ellipsis btn-text">
          {{ activeStyle.toString() ? activeStyle.toString() : "全部风格" }}
        </div>
      </van-button>
      <van-button
        style="border:none;color:#696974"
        icon="arrow-down"
        icon-position="right"
        size="small"
        @click="show3 = true"
      >
        <div class="van-ellipsis btn-text">
          {{
            activeScopeApplication.toString()
              ? activeScopeApplication.toString()
              : "全部范围"
          }}
        </div>
      </van-button>
    </div>
    <div class="search-result" v-if="goodsList.length > 0">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          :immediate-check="false"
          @load="onLoad"
          offset="30"
        >
          <!-- <van-grid :column-num="2" :border="false">
            <van-grid-item v-for="item in goodsList" :key="item.materialId">
              <goods-item :good-info="item" />
            </van-grid-item>
          </van-grid> -->
          <div class="goods-item-box">
            <div
              class="goods-item"
              v-for="item in goodsList"
              :key="item.materialId"
            >
              <goods-item :good-info="item" />
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="search-result-nothing" v-else>
      抱歉,没有找到商品哦
    </div>
  </div>
  <van-popup
    round
    :show="show2"
    :close-on-click-overlay="false"
    @click-overlay="show2 = false"
  >
    <div class="wrapper">
      <div>
        <div class="title">风格</div>
        <div style="margin-top:10px">
          <span
            v-for="(item, index) in styleList"
            :key="index"
            :class="activeStyle.includes(item) ? 'active' : 'Classification'"
            @click="oncheckStyle(item)"
          >
            {{ item }}</span
          >
        </div>
      </div>

      <div class="bottom-btns">
        <van-button block round style="flex:1" @click="handleResetSearch"
          >重置</van-button
        >
        <van-button
          block
          round
          style="flex:2"
          type="primary"
          @click="handleComfirmSearch"
          >确定</van-button
        >
      </div>
    </div>
  </van-popup>
  <van-popup
    round
    :show="show3"
    :close-on-click-overlay="false"
    @click-overlay="show3 = false"
  >
    <div class="wrapper">
      <div>
        <div class="title">适用范围</div>
        <div style="margin-top:10px">
          <span
            v-for="(item, index) in scopeApplicationList"
            :key="index"
            :class="
              activeScopeApplication.includes(item)
                ? 'active'
                : 'Classification'
            "
            @click="oncheckScopeApplication(item)"
          >
            {{ item }}</span
          >
        </div>
      </div>
      <div class="bottom-btns">
        <van-button block round style="flex:1" @click="handleResetSearch"
          >重置</van-button
        >
        <van-button
          block
          round
          style="flex:2"
          type="primary"
          @click="handleComfirmSearch"
          >确定</van-button
        >
      </div>
    </div>
  </van-popup>
  <van-popup
    round
    :show="show"
    :close-on-click-overlay="false"
    @click-overlay="show = false"
  >
    <div class="wrapper">
      <div>
        <div class="title">一级分类</div>
        <div class="clear-float" style="margin-top:10px">
          <span
            v-for="(item, index) in categoryList"
            :key="index"
            :class="firstLevel == item.categoryId ? 'active' : 'Classification'"
            @click="oncheckFirstLevel(item.categoryId, item.categoryName)"
          >
            {{ item.categoryName }}
          </span>
        </div>
        <div v-if="firstLevel">
          <div class="title">二级分类</div>
          <div style="margin-top:10px">
            <span
              v-for="(item, index) in categoryList.filter(
                (i) => i.categoryId === firstLevel
              )[0]?.childrenCategoryList"
              :key="index"
              :class="
                secondLevel == item.categoryId ? 'active' : 'Classification'
              "
              @click="oncheckSecondLevel(item.categoryId, item.categoryName)"
            >
              {{ item.categoryName }}</span
            >
          </div>
        </div>
      </div>
      <div class="bottom-btns">
        <van-button block round style="flex:1" @click="handleResetSearch"
          >重置</van-button
        >
        <van-button
          block
          round
          style="flex:2"
          type="primary"
          @click="handleComfirmSearch"
          >确定</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import { Toast } from "vant";
import { computed, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  getSidebarCategoryInfo,
  initGetMaterialList,
  listShopMaterialByPage,
} from "../../api/common";
import GoodsItem from "../../components/GoodsItem.vue";
import cate from "./temp";
const route = useRoute();
const router = useRouter();
const store = useStore();
const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);
const show = ref(false);
const show2 = ref(false);
const show3 = ref(false);
const categoryList = ref(cate);
const firstLevel = ref(null);
const secondLevel = ref(null);
const categoryName = ref(null);
const token = computed(() => store.getters.token);
const materialId = computed(() => store.getters.materialId);
const goodsList = ref([]);
const searchParam = reactive({
  token: token.value,
  page: 1,
  pageSize: 30,
  param: {
    materialStockId: materialId.value,
    categoryId: route.query.categoryId,
    brandId: "",
    style: "",
    scope: "",
    search: route.query.search,
  },
});
categoryName.value = route.query.categoryName;
const styleList = ref([]);
const scopeApplicationList = ref([]);
const activeScopeApplication = ref([]);
const activeStyle = ref([]);

const oncheckStyle = (name) => {
  activeStyle.value.includes(name)
    ? activeStyle.value.splice(activeStyle.value.indexOf(name), 1)
    : activeStyle.value.push(name);
};
const oncheckScopeApplication = (name) => {
  activeScopeApplication.value.includes(name)
    ? activeScopeApplication.value.splice(
        activeScopeApplication.value.indexOf(name),
        1
      )
    : activeScopeApplication.value.push(name);
};
const oncheckFirstLevel = (id, name) => {
  firstLevel.value = id;
  categoryName.value = name;
  secondLevel.value = "";
};
const oncheckSecondLevel = (id, name) => {
  secondLevel.value = id;
  categoryName.value = name;
};
const onRefresh = () => {
  // 清空列表数据
  finished.value = false;
  // 重新加载数据
  // 将 loading 设置为 true，表示处于加载状态
  loading.value = true;
  onLoad();
};
const onLoad = async () => {
  if (refreshing.value) {
    goodsList.value = [];
    searchParam.page = 1;
    await loadGoodsList();
    refreshing.value = false;
    return;
  }
  searchParam.page++;
  await loadGoodsList();
};
const loadGoodsList = async () => {
  Toast.loading({
    message: "加载中...",
    forbidClick: true,
    duration: 300,
  });
  let data = await listShopMaterialByPage(searchParam);
  loading.value = false;
  if (data.list.length === 0) {
    finished.value = true;
    return;
  }
  goodsList.value = [...goodsList.value, ...data.list];
  Toast.clear(true);
};
const onSearch = async () => {
  searchParam.page = 1;
  let data = await listShopMaterialByPage(searchParam);
  goodsList.value = data.list;
};
const handleResetSearch = async () => {
  //重置分类筛选
  firstLevel.value = "";
  secondLevel.value = "";
  categoryName.value = "";
  searchParam.param.categoryId = "";
  //重置风格筛选
  activeStyle.value = [];
  searchParam.param.style = "";
  //重置使用范围筛选
  activeScopeApplication.value = [];
  searchParam.param.scope = "";
  //关闭所有弹窗
  show3.value = false;
  show2.value = false;
  show.value = false;

  //翻到第一页
  onSearch();
};
const handleComfirmSearch = async () => {
  if (firstLevel.value) {
    searchParam.param.categoryId = firstLevel.value;
    if (secondLevel.value) {
      searchParam.param.categoryId = secondLevel.value;
    }
  }
  if (activeStyle.value.length) {
    searchParam.param.style = activeStyle.value.toString();
  }
  if (activeScopeApplication.value.length) {
    searchParam.param.scope = activeScopeApplication.value.toString();
  }
  //关闭所有弹窗
  show3.value = false;
  show2.value = false;
  show.value = false;
  onSearch();
};
const onClickBack = () => {
  router.back();
};
//获取分类可选项
const loadCategoryList = async () => {
  let data = await getSidebarCategoryInfo({
    token: token.value,
    materialId: materialId.value,
  });
  categoryList.value = data;
};
//获取风格可选项
//获取适用范围可选项
const initSelections = async () => {
  let data = await initGetMaterialList({
    token: token.value,
    materialStockId: materialId.value,
  });
  styleList.value = data.styleList;
  scopeApplicationList.value = data.scopeApplicationList;
  categoryList.value = data.categoryList;
};

//执行
loadGoodsList();
// loadCategoryList();
initSelections();
</script>

<style lang="scss" scoped>
.goodsSearch {
  .margin-right-12{
    margin-right: 12px;
  }
  .search-result {
    background: rgb(250, 250, 250);
    padding: 0 12px;
    .goods-item-box {
      display: flex;
      flex-wrap: wrap;
      margin-top: 12px;
      .goods-item {
        margin-bottom: 9px;
      }
      .goods-item:nth-child(2n-1) {
        margin-right: 9px;
      }
    }
  }
  .search-result-nothing {
    height: 500px;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    color: rgb(105, 105, 116);
    font-size: 14px;
  }
}
.wrapper {
  min-height: 200px;
  padding: 8px;
  width: 300px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    color: #171725;
    font-size: 14px;
  }
  .bottom-btns {
    display: flex;
    height: 50px;
  }
}

.active {
  float: left;
  margin-left: 10px;
  padding: 4px 12px;
  background: #d6e4ff;
  margin-bottom: 10px;
  border-radius: 16px;
  font-size: 14px;
  color: #4d6ff0;
}
.Classification {
  float: left;
  margin-left: 10px;
  padding: 4px 12px;
  background: #f7f7f7;
  margin-bottom: 10px;
  border-radius: 16px;
  font-size: 14px;
}
.clear-float {
  &::after {
    content: "";
    clear: both;
    display: block;
  }
}
.btn-text {
  width: 90px;
}
</style>
