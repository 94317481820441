const data =
    [{
        "id": 1,
        "name": "装修辅材",
        "orderNum": 1,
        "stateName": "启用",
        "belongTypeName": "商品,模型,物料",
        "nonDisplaySystemName": "",
        "children": [{
            "id": 2,
            "name": "石材",
            "orderNum": 1,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 4,
                "name": "天然石材",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 5,
                "name": "人造石材",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 6,
            "name": "板材",
            "orderNum": 2,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 7,
                "name": "护墙板",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 8,
                "name": "木工板",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": "3D系统"
            }, {
                "id": 9,
                "name": "饰面板",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 10,
                "name": "铝塑板",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 11,
                "name": "欧松板",
                "orderNum": 5,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": "2D系统"
            }, {
                "id": 12,
                "name": "多层板",
                "orderNum": 6,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 13,
                "name": "密度板",
                "orderNum": 7,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 14,
                "name": "防火板",
                "orderNum": 8,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 15,
            "name": "龙骨",
            "orderNum": 3,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 16,
                "name": "木质龙骨",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 17,
                "name": "金属龙骨",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 18,
            "name": "石膏",
            "orderNum": 4,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 19,
                "name": "石膏板",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 20,
                "name": "石膏角线",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 21,
                "name": "石膏配件",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 22,
                "name": "腻子膏",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 23,
                "name": "石膏粉",
                "orderNum": 5,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 24,
            "name": "扣板",
            "orderNum": 5,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 25,
                "name": "金属扣板",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 26,
            "name": "涂料",
            "orderNum": 6,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 27,
                "name": "乳胶漆",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 28,
                "name": "油漆",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 29,
                "name": "防火涂料",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 30,
            "name": "电线/管材",
            "orderNum": 7,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 31,
                "name": "强电",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 32,
                "name": "弱电",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 33,
                "name": "线管",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 34,
                "name": "水管",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 35,
                "name": "管件",
                "orderNum": 5,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 36,
            "name": "水泥河沙",
            "orderNum": 8,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 38,
                "name": "水泥",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 39,
                "name": "河沙",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 40,
            "name": "电箱",
            "orderNum": 9,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 41,
                "name": "强电箱",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 42,
                "name": "弱电箱",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 43,
            "name": "工具",
            "orderNum": 10,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": ""
        }, {
            "id": 44,
            "name": "其他",
            "orderNum": 11,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": ""
        }, {
            "id": 45,
            "name": "五金",
            "orderNum": 12,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": ""
        }, {
            "id": 421,
            "name": "测试分类====",
            "orderNum": 111,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "3D系统"
        }, {
            "id": 324,
            "name": "大叔说的水电费",
            "orderNum": 10000,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "2D系统,3D系统"
        }]
    }, {
        "id": 560,
        "name": "装修MVP物料1",
        "orderNum": 1,
        "stateName": "启用",
        "belongTypeName": "物料,商品",
        "nonDisplaySystemName": "",
        "children": [{
            "id": 581,
            "name": "MVP石头",
            "orderNum": 1,
            "stateName": "启用",
            "belongTypeName": "物料,商品",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 598,
                "name": "石头",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "物料,商品",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 600,
            "name": "物料测试11",
            "orderNum": 4,
            "stateName": "启用",
            "belongTypeName": "物料",
            "nonDisplaySystemName": ""
        }]
    }, {
        "id": 46,
        "name": "装修建材",
        "orderNum": 2,
        "stateName": "启用",
        "belongTypeName": "模型,商品,物料",
        "nonDisplaySystemName": "",
        "children": [{
            "id": 47,
            "name": "门",
            "orderNum": 1,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 48,
                "name": "实木门",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 49,
                "name": "复合门",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 50,
                "name": "合金门",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 51,
            "name": "窗",
            "orderNum": 2,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 52,
                "name": "实木窗",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 53,
                "name": "复合窗",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 54,
                "name": "合金窗",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 55,
            "name": "地板",
            "orderNum": 3,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 56,
                "name": "实木地板",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 57,
                "name": "强化地板",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 58,
                "name": "复合地板",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 59,
                "name": "竹地板",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 60,
            "name": "瓷砖",
            "orderNum": 4,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 61,
                "name": "墙砖",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 62,
                "name": "地砖",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 63,
                "name": "墙地砖",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 64,
                "name": "瓷砖配件",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 65,
            "name": "墙纸",
            "orderNum": 5,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 66,
                "name": "PVC墙纸",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 67,
                "name": "无纺布",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 68,
                "name": "纤维",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 69,
                "name": "纸",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 70,
            "name": "楼梯",
            "orderNum": 6,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 71,
                "name": "木质楼梯",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 72,
                "name": "金属楼梯",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 73,
            "name": "五金配件",
            "orderNum": 7,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 74,
                "name": "门锁",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 76,
                "name": "门窗五金",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 77,
                "name": "地板配件",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }]
    }, {
        "id": 78,
        "name": "厨房卫浴",
        "orderNum": 3,
        "stateName": "启用",
        "belongTypeName": "商品,模型,物料",
        "nonDisplaySystemName": "",
        "children": [{
            "id": 79,
            "name": "厨房",
            "orderNum": 1,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 80,
                "name": "橱柜",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 81,
                "name": "橱盆/水槽",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 82,
                "name": "厨房龙头",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 83,
                "name": "角阀",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 84,
                "name": "厨房挂件",
                "orderNum": 5,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 85,
                "name": "沥水篮",
                "orderNum": 6,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 86,
                "name": "其他厨房配件",
                "orderNum": 7,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 87,
            "name": "卫浴",
            "orderNum": 2,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 88,
                "name": "浴室柜",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 89,
                "name": "浴室镜",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 90,
                "name": "坐便器",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 91,
                "name": "蹲便器",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 92,
                "name": "水箱",
                "orderNum": 5,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 93,
                "name": "浴缸",
                "orderNum": 6,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 94,
                "name": "淋浴房",
                "orderNum": 7,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 95,
                "name": "花洒",
                "orderNum": 8,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 96,
                "name": "台盆",
                "orderNum": 9,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 97,
                "name": "卫浴龙头",
                "orderNum": 10,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 98,
                "name": "卫浴挂件",
                "orderNum": 11,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 99,
                "name": "地漏",
                "orderNum": 12,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 100,
                "name": "坐便盖",
                "orderNum": 13,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 101,
                "name": "卫浴套装",
                "orderNum": 14,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 102,
                "name": "其它卫浴配件",
                "orderNum": 15,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }]
    }, {
        "id": 103,
        "name": "灯具灯饰",
        "orderNum": 4,
        "stateName": "启用",
        "belongTypeName": "商品,模型,物料",
        "nonDisplaySystemName": "",
        "children": [{
            "id": 104,
            "name": "吊灯",
            "orderNum": 1,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": ""
        }, {
            "id": 105,
            "name": "吸顶灯",
            "orderNum": 2,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": ""
        }, {
            "id": 106,
            "name": "筒灯射灯",
            "orderNum": 3,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": ""
        }, {
            "id": 107,
            "name": "落地灯",
            "orderNum": 4,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": ""
        }, {
            "id": 108,
            "name": "壁灯",
            "orderNum": 5,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": ""
        }, {
            "id": 109,
            "name": "台灯",
            "orderNum": 6,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": ""
        }, {
            "id": 110,
            "name": "轨道灯",
            "orderNum": 7,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": ""
        }, {
            "id": 111,
            "name": "灯管灯带",
            "orderNum": 8,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": ""
        }, {
            "id": 112,
            "name": "开关/插座",
            "orderNum": 9,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": ""
        }]
    }, {
        "id": 113,
        "name": "家具",
        "orderNum": 5,
        "stateName": "启用",
        "belongTypeName": "模型,商品,物料",
        "nonDisplaySystemName": "",
        "children": [{
            "id": 114,
            "name": "沙发",
            "orderNum": 1,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "2D系统",
            "children": [{
                "id": 115,
                "name": "组合沙发",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 116,
                "name": "多人沙发",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 117,
                "name": "双人沙发",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 118,
                "name": "单人沙发",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 119,
                "name": "沙发贵妃椅",
                "orderNum": 5,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 120,
                "name": "脚踏",
                "orderNum": 6,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 140,
                "name": "沙发凳",
                "orderNum": 7,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 121,
            "name": "床",
            "orderNum": 2,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 122,
                "name": "单/双人床",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 123,
                "name": "儿童床",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 124,
                "name": "多功能组合床",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 125,
                "name": "高低床/母子床",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 126,
                "name": "榻榻米床",
                "orderNum": 5,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 142,
                "name": "床尾凳",
                "orderNum": 6,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 127,
                "name": "床垫",
                "orderNum": 7,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 128,
            "name": "桌子/几",
            "orderNum": 3,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 130,
                "name": "餐桌",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 131,
                "name": "书桌",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 133,
                "name": "茶几",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 134,
                "name": "边几",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 129,
                "name": "组合桌",
                "orderNum": 5,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 135,
            "name": "椅子/凳子",
            "orderNum": 4,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 136,
                "name": "餐椅",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 137,
                "name": "书桌椅",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 138,
                "name": "吧台椅",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 139,
                "name": "凳子",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 141,
                "name": "躺椅",
                "orderNum": 5,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 143,
                "name": "吊椅",
                "orderNum": 6,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 144,
                "name": "按摩椅",
                "orderNum": 7,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 145,
                "name": "蒲团坐",
                "orderNum": 8,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 146,
            "name": "柜架",
            "orderNum": 5,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 147,
                "name": "鞋柜",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 148,
                "name": "电视柜",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 149,
                "name": "餐边柜",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 150,
                "name": "酒柜",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 151,
                "name": "衣柜",
                "orderNum": 5,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 152,
                "name": "床头柜",
                "orderNum": 6,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 153,
                "name": "玄关柜",
                "orderNum": 7,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 154,
                "name": "书柜",
                "orderNum": 8,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 155,
                "name": "隔板",
                "orderNum": 9,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 156,
                "name": "斗柜",
                "orderNum": 10,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 157,
                "name": "置物架",
                "orderNum": 11,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 158,
                "name": "花架",
                "orderNum": 12,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 159,
                "name": "衣帽架",
                "orderNum": 13,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 160,
                "name": "吧台",
                "orderNum": 14,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 132,
                "name": "梳妆台",
                "orderNum": 15,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 176,
                "name": "试衣镜",
                "orderNum": 16,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 179,
                "name": "全身镜",
                "orderNum": 17,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }]
    }, {
        "id": 161,
        "name": "软装",
        "orderNum": 6,
        "stateName": "启用",
        "belongTypeName": "商品,模型,物料",
        "nonDisplaySystemName": "",
        "children": [{
            "id": 162,
            "name": "布艺软式\t",
            "orderNum": 1,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 163,
                "name": "窗帘",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 164,
                "name": "地毯",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 165,
                "name": "抱枕/靠垫",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 166,
                "name": "床品",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 167,
            "name": "装饰画",
            "orderNum": 2,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 168,
                "name": "国画书法",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 169,
                "name": "手绘油画",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 170,
                "name": "艺术微喷",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 171,
            "name": "饰品\t",
            "orderNum": 3,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 172,
                "name": "墙贴",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 173,
                "name": "挂件",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 174,
                "name": "摆件",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 175,
            "name": "镜子\t",
            "orderNum": 4,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 177,
                "name": "化妆镜",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 178,
                "name": "装饰镜",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 180,
            "name": "屏风\t",
            "orderNum": 5,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 181,
                "name": "中式屏风",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 182,
                "name": "欧式屏风",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }]
    }, {
        "id": 183,
        "name": "电器",
        "orderNum": 7,
        "stateName": "启用",
        "belongTypeName": "商品,模型,物料",
        "nonDisplaySystemName": "",
        "children": [{
            "id": 184,
            "name": "厨电",
            "orderNum": 1,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 185,
                "name": "抽油烟机",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 186,
                "name": "燃气灶",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 187,
                "name": "热水器",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 188,
                "name": "浴霸",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 189,
                "name": "蒸烤一体机",
                "orderNum": 5,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 190,
                "name": "消毒柜",
                "orderNum": 6,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 191,
                "name": "洗碗机",
                "orderNum": 7,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 192,
                "name": "厨卫小家电",
                "orderNum": 8,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }]
        }, {
            "id": 193,
            "name": "家电",
            "orderNum": 2,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": "",
            "children": [{
                "id": 194,
                "name": "电视",
                "orderNum": 1,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 195,
                "name": "空调",
                "orderNum": 2,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 196,
                "name": "冰箱",
                "orderNum": 3,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 197,
                "name": "洗衣机",
                "orderNum": 4,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 198,
                "name": "取暖电器",
                "orderNum": 5,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": ""
            }, {
                "id": 199,
                "name": "空气净化器",
                "orderNum": 6,
                "stateName": "启用",
                "belongTypeName": "商品,模型,物料",
                "nonDisplaySystemName": "3D系统"
            }]
        }, {
            "id": 200,
            "name": "智能家居",
            "orderNum": 3,
            "stateName": "启用",
            "belongTypeName": "商品,模型,物料",
            "nonDisplaySystemName": ""
        }]
    }, {
        "id": 585,
        "name": "MVP物料2",
        "orderNum": 990,
        "stateName": "启用",
        "belongTypeName": "商品,模型,物料",
        "nonDisplaySystemName": ""
    }]
export default data